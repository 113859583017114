import "../auxillary.scss"
import React from "react"
import { FooterContent } from "../../Footer/Footer"

import termsImageOne from "../../../images/terms/terms_1.png"
import termsImageTwo from "../../../images/terms/terms_2.png"
import termsImageThree from "../../../images/terms/terms_3.png"

function PrivacyPolicy() {
  return (
    <>
      <section className="auxillary-wrapper">
        <div className="auxillary cookies-policy">
          <div className="auxillary__inner">
            <h1>Privacy Policy</h1>
            <div className="auxillary__content-column">
              <p>Effective February 27, 2023 Notice Version 1.0</p>
              <p>
                <strong>Data Controller Contact Information:</strong>
              </p>
              <p>United Talent Agency, LLC.</p>
              <p>9336 Civic Center Dr, Beverly Hills, CA 90210</p>
              <p>1-(877) 678-1770 </p>
              <p>
                <a href="mailto:privacy@unitedtalent.com ">
                  privacy@unitedtalent.com
                </a>
              </p>
              <p>
                Our privacy notice governs our privacy practices when visiting{" "}
                <a
                  href="https://www.unitedtalent.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.thedigitalbrandarchitects.com
                </a>
                ,{" "}
                <a
                  href="https://www.mediahound.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.mediahound.com
                </a>
                ,{" "}
                <a
                  href="https://www.medialink.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.medialink.com
                </a>
                ,{" "}
                <a
                  href="https://www.siberia.io"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.siberia.io
                </a>
                ,{" "}
                <a
                  href="http://www.utaartistspace.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.utaartistspace.com
                </a>
                , and{" "}
                <a
                  href="https://www.uta.vc"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.uta.vc
                </a>{" "}
                (“Website”).
              </p>
              <p>
                Our privacy notice tells you what personal and non-Personal
                Information we collect from you, how we collect them, how we
                protect them, how we disclose them, how you can access and
                change them, and how you can limit our disclosing of them. Our
                privacy notice also explains certain legal rights that you have
                about your data. This policy applies to information we collect:
              </p>
              <ul>
                <li>On this Website.</li>
                <li>
                  In email, text, and other electronic messages between you and
                  this Website.
                </li>
                <li>
                  When you interact with our advertising and applications on
                  third-party websites and services if those applications or
                  advertising include links to this policy.
                </li>
              </ul>
              <p>It does not apply to information collected by:</p>
              <ul>
                <li>
                  Us offline or through any other means, including on any other
                  website operated by Company or any third party (including our
                  affiliates and subsidiaries); or
                </li>
                <li>
                  Any third party (including our affiliates and subsidiaries),
                  including through any application or content (including
                  advertising) that may link to or be accessible from or on the
                  Website.
                </li>
              </ul>
              <p>
                Any capitalized terms not defined herein will have the same
                meaning as those defined elsewhere on our website.
              </p>
              <h2>Definitions</h2>
              <p>
                &apos;NON-PERSONAL INFORMATION&apos; (NPI) means any information
                that is in no way personally identifiable.
              </p>
              <p>
                &apos;PERSONAL INFORMATION&apos; (PI) means any information
                relating to an identified or identifiable natural person
                (&apos;data subject&apos;); an identifiable natural person can
                be identified directly or indirectly by reference to an
                identifier such as a name, an identification number, location
                data, an online identifier, or to one or more factors specific
                to the physical, physiological, genetic, mental, economic,
                cultural, or social identity of that natural person. PI is, in
                many ways, the same as Personally Identifiable Information
                (PII). However, PI is broader in scope and covers more data.
              </p>
              <h2>Your Rights</h2>
              <p>
                <i>
                  Contact us using the information at the top of this privacy
                  notice to exercise any of your legal rights contained within
                  this privacy notice. We will respond to your request within 30
                  days of receiving it. Occasionally it may take longer for
                  multiple requests or complex circumstances.
                </i>
              </p>
              <p>
                When using our Website and submitting PI to us, you have certain
                rights under privacy laws in the United States, including the
                California Consumer Privacy Act (CCPA), the California Privacy
                Rights Act (CPRA), and privacy laws of other countries including
                the European Union General Data Protection Regulation (the EU
                GDPR), the UK General Data Protection Regulation (the UK GDPR),
                the UK Data Protection Act of 2018, Canada’s Personal UTA
                Website Privacy Notice Page 4 of 13 Information Protection and
                Electronic Documents Act (PIPEDA), and other global privacy
                laws. Even if not listed here, we will make reasonable efforts
                to honor data subject access requests even though we may be
                under no legal obligation to do so. However, we reserve the
                right to decline any data subject access request that we are not
                legally obligated to comply with. Your rights include but are
                not limited to the following:
              </p>
              <ol>
                <li>
                  The right to one or more means you can submit requests under
                  this privacy notice, including (at minimum) a toll-free
                  telephone number and an email address if the business operates
                  a storefront and a website. If the company operates only an
                  Internet website, it must have a dedicated email address where
                  you can submit requests for information required to be
                  disclosed by law.
                </li>
                <li>
                  The right to know whether your PI is sold or disclosed and to
                  whom.
                </li>
                <li>
                  The right to request that we do not sell any of your PI.
                </li>
                <li>
                  The right to be informed about the PI we collect from you and
                  how we process them.
                </li>
                <li>
                  The right to get confirmation that your PI is being processed
                  and how you can access your PI.
                </li>
                <li>
                  The right to correct your PI if inaccurate or incomplete.
                </li>
                <li>
                  The right to request the removal or deletion of your PI if
                  there is no compelling reason for us to continue processing
                  them. However, the right to deletion is not absolute. It can
                  be overridden to continue data processing in some cases where
                  we still have a legal ground or overriding legitimate interest
                  to process your data.
                </li>
                <li>
                  The right to &apos;block&apos; or restrict the processing of
                  your PI. When your PI is restricted, we are permitted to store
                  your PI but not to process them further.
                </li>
                <li>
                  The right to request the PI you provided and use them for your
                  purposes. Upon express request, we will provide your data to
                  you or another service or product provider within 30 days of
                  your request, subject to commercial and industrial secrets.
                </li>
                <li>
                  The right to object to us processing your PI for the following
                  reasons: a. Processing was based on legitimate interests or
                  the performance of a task in the public interest/exercise of
                  official authority (including profiling). b. Direct marketing
                  and targeted advertising (including profiling). c. Processing
                  for purposes of scientific/historical research and statistics
                </li>
                <li>
                  The right that we limit the collection of your PI to that
                  which is “adequate, relevant and reasonably necessary” with
                  the purposes for which the data is processed.
                </li>
                <li>
                  The right to file a complaint with supervisory authorities if
                  your information has not been processed in compliance with
                  your rights under privacy laws. Please see further details in
                  the last section <strong>HOW TO COMPLAIN.</strong>
                </li>
              </ol>
              <h2>Information We Collect and How We Collect It</h2>
              <p>
                Generally, you control the amount and type of information that
                you provide to us when using our Website. We collect several
                types of information from and about users of our Website,
                including information:
              </p>
              <ul>
                <li>
                  By which you may be personally identified, such as name,
                  postal address, e-mail address, telephone number, or any other
                  identifier by which you may be contacted online or offline
                  (“personal information”).
                </li>
                <li>
                  That is about you but individually does not identify you, such
                  as organization, company, or comments you may provide; and/or
                </li>
                <li>
                  About your internet connection, the equipment you use to
                  access our website and usage details.
                </li>
              </ul>
              <p>We collect this information:</p>
              <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Automatically as you navigate through the site. Information
                  collected automatically may include usage details, IP
                  addresses, and information collected through cookies, web
                  beacons, and other tracking technologies.
                </li>
                <li>From third parties, for example, our business partners.</li>
              </ul>
              <h2>Automatic Information </h2>
              <p>
                We automatically receive information from your web browser or
                mobile device. This information may include the IP address of
                your computer or the proxy server you use to access the
                Internet, your Internet service provider&apos;s name, your web
                browser type, the type of mobile device, your computer operating
                system, and data about your browsing activity when using our
                Website. We use all this information to help improve our
                Website.
              </p>
              <h2>When Entering and Using Our Website </h2>
              <p>
                When you enter and use our Website and agree to accept cookies,
                some cookies may contain your PI.
              </p>
              <h2>Our Use of Cookies</h2>
              <p>
                Our Website uses cookies. A cookie is a small piece of data, or
                a text file downloaded to your computer or mobile device when
                you access certain websites. Cookies may contain text that can
                be read by the web server that delivered the cookie to you. The
                text in the cookie consists of a sequence of letters and numbers
                that uniquely identifies your computer or mobile device; it may
                also contain other information.
              </p>
              <p>
                By agreeing to accept our use of cookies, you are giving us and
                the third parties with whom we partner permission to place,
                store, and access some or all the cookies described below on
                your computer and mobile device.
              </p>
              <h3>Strictly Necessary Cookies </h3>
              <p>
                - These cookies are necessary for the website&apos;s proper
                functioning, such as displaying content, logging in, confirming
                your session, responding to your request for services, and other
                functions.
              </p>
              <h3>Performance Cookies </h3>
              <p>
                - These cookies collect information about the use of the
                website, such as pages visited, traffic sources, users’
                interests, content management, and other website measurements.
              </p>
              <h3>Functional Cookies </h3>
              <p>
                - These cookies enable the website to remember users’ choices,
                such as their language, usernames, and other decisions while
                using the website.
              </p>
              <h3>Session Cookies </h3>
              <p>
                - These cookies allow websites to link the actions of a user
                during a browser session. Session cookies also permit users to
                be recognized as they navigate a website so that any item or
                page changes are remembered from page to page. Session cookies
                expire after a browser session.
              </p>
              <h3>Persistent Cookies </h3>
              <p>
                - These cookies are stored on a user&apos;s device between
                browser sessions, which allows the user&apos;s preferences or
                actions across a website or different websites to be remembered.
                Persistent cookies may be used to remember users&apos; choices
                and preferences when using a website or target advertising
              </p>

              <h3>We may also use cookies for the following: </h3>

              <p>
                - Identifying the areas of our Website that you have visited
              </p>
              <p>- Personalizing the content that you see on our Website</p>
              <p>- Our Website analytics </p>
              <p>
                - Remembering your preferences, settings, and log-in details
              </p>
              <p>- Speed up your searches</p>

              <p>
                Most web browsers can be set to disable the use of cookies.
                However, if you disable cookies, you may not be able to access
                features on our Website correctly, or at all.
              </p>

              <h2>Web Beacons</h2>

              <p>
                Pages of the Website and our e-mails may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit Company,
                for example, to count users who have visited those pages or
                opened an email and for other related website statistics (for
                example, recording the popularity of certain website content and
                verifying system and server integrity).
              </p>

              <h2>Chat Software or Contact Forms </h2>

              <p>
                Our Website may contain chat software or contact forms that
                enable visitors to communicate with us online or offline by
                email. When you use our chat software or contact forms, we may
                collect some or all the following information: your email
                address, first name, last name, location, and any other
                information you willingly choose to give us.
              </p>

              <h2>How Your PI is Used </h2>

              <p>We use the information we receive from you to: </p>

              <ul>
                <li>To present our Website and its contents to you.</li>
                <li>
                  To provide you with information, products, or services that
                  you request from us.
                </li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered between you and us, including for
                  billing and collection.
                </li>
                <li>
                  To notify you about changes to our Website or any products or
                  services we offer or provide though it.
                </li>
                <li>
                  In any other way we may describe when you provide the
                  information.
                </li>
                <li>
                  For any other purpose to fulfill our legitimate interests or
                  with your consent.
                </li>
              </ul>

              <h2>Communications and Emails </h2>

              <p>
                When we communicate with you about our Website, we will use the
                email address you provided when registering as a user, We may
                also send you emails with promotional information about our
                Website or offers from us unless you have opted out of receiving
                such information. You can change your contact preferences at any
                time through your account or by contacting us using the
                information at the top of this privacy notice.
              </p>

              <h2>Disclosing Your Data to Third Parties </h2>

              <p>
                We do not sell or rent your PI to third parties for marketing
                purposes. However, for data aggregation purposes, we may use
                your NPI, which might be sold to other parties at our
                discretion. Any such data aggregation would not contain any of
                your PI. We do not control third parties’ collection or use of
                your information to serve interest-based advertising. However,
                these third parties may provide you with ways to choose not to
                have your information collected or used in this way. You can opt
                out of receiving targeted ads from members of the Network
                Advertising Initiative (“NAI”) on the NAI’s website:
                https://www. networkadvertising.org/.
              </p>

              <h3>
                Disclosing Your PI to Service Providers and or Contractors
              </h3>

              <p>
                Sometimes we give your PI to service providers and or
                contractors we hire to provide services to us. These service
                providers and contractors may include but are not limited to
                payment processors, data management services, help desk
                providers, accountants, law firms, state and federal courts,
                auditors, and email service providers.
              </p>

              <h2>Legally Required Releases of Information </h2>

              <p>We may disclose your PI if such disclosure is:</p>

              <ol>
                <li>Required by subpoena, law, or other legal processes</li>
                <li>
                  Necessary to assist law enforcement officials or government
                  enforcement agencies
                </li>
                <li>
                  Necessary to protect us from legal action or claims from third
                  parties, including you
                </li>
                <li>
                  Necessary to protect the legal rights, personal and real
                  property, or the personal safety of our company, users,
                  employees, and business partners
                </li>
              </ol>

              <h2>Disclosures to Successors</h2>

              <p>
                If our business is sold or merges in whole or in part with
                another business that would become responsible for providing the
                website to you, we retain the right to transfer your PI to the
                new business. The new company would have the right to use your
                PI according to the terms of this privacy notice and any changes
                to this privacy notice as instituted by the new business.
              </p>

              <h2>Data Retention Policy</h2>

              <p>
                <strong>
                  We retain your PI if it is necessary to provide our services
                  and products to you and fulfill the purposes outlined in our
                  privacy notice, subject to any legal obligations that may
                  require a longer retention period. PI associated with your
                  data will be kept until it is no longer necessary to provide
                  our services and/or products or you ask us to delete them
                  [subject to any legal obligations that may require a longer
                  retention period].
                </strong>
              </p>

              <p>
                The criteria used to determine our retention periods include:
              </p>

              <ul>
                <li>
                  The length of time we have a continuing relationship with you
                  and provide the services and products you requested
                </li>
                <li>
                  Whether there is a legal obligation to which we are subject
                  (for example, specific laws require us to keep records of your
                  transactions for a certain period before we can delete them)
                </li>
                <li>
                  Whether retention is advisable considering our legal position
                  (such as for statutes of limitations, litigation, or
                  regulatory investigations)
                </li>
              </ul>

              <p>
                PI about you that is no longer necessary to provide our products
                or services may be deidentified and aggregated with other NPI to
                provide insights, such as statistics on the use of our services
                and/or products. This information will be de-identified from
                your name and additional personally identifiable information.
              </p>

              <h2>Do Not Track Settings </h2>

              <p>
                Some web browsers have settings that enable you to request that
                our Website not track your movement within our Website. Our
                Website does not obey such settings when transmitted to and
                detected by our Website. You can turn off your browser&apos;s
                tracking features and other security settings by referring to
                your browser&apos;s user manual.
              </p>

              <h2>Protecting Children&apos;s Privacy </h2>

              <p>
                Our Website is not intended for nor targeted towards children as
                defined by applicable law. We do not knowingly collect PI from
                minors under the age of 13. If you are a parent or guardian and
                believe that your child is providing PI to us via our Website,
                please get in touch with us at privacy@unitedtalent.com. Before
                we remove any information, we may ask for proof of
                identification to prevent malicious removal of account
                information. If we discover that a minor under the age of 13 is
                providing access to us via our Website, we will delete their
                information within a reasonable period.
              </p>

              <h2>Our Email Policy </h2>

              <p>
                You can always opt out of receiving email correspondence from us
                or our affiliates. However, we will not sell, rent, or trade
                your email address to any unaffiliated third party without your
                permission except in the sale or transfer of our company or if
                our company files for bankruptcy as described in the section
                <strong>Disclosures to Successors.</strong>
              </p>

              <h2>Our Security Policy </h2>

              <p>
                We use commercially reasonable measures to secure information
                collected through the Website. Unfortunately, the transmission
                of information via the internet is not completely secure.
                Although we do our best to protect your personal information, we
                cannot guarantee the security of your personal information
                transmitted to our Website. Any transmission of personal
                information is at your own risk. We are not responsible for
                circumvention of any privacy settings or security measures
                contained on the Website.
              </p>

              <p>
                We restrict access to PI to our employees, contractors and
                agents who need to know that information to transmit, store or
                process it, who are subject to contractual confidentiality
                obligations consistent with this privacy policy, and who may be
                disciplined or terminated if they fail to meet these
                obligations.
              </p>

              <p>
                If any information in our possession or under our control is
                compromised because of a security breach, we will take
                reasonable steps to investigate the situation and, where
                appropriate, notify those individuals whose information may have
                been compromised and take other steps in accordance with
                applicable laws or regulations.
              </p>

              <h2>Transferring PI From Other Countries </h2>

              <p>
                PI that we collect from you may be stored, processed, and
                transferred among any countries in which we operate. The
                European Union has yet to find the United States and some other
                countries to have an acceptable level of protection of PI under
                Article 45 of the GDPR. Our company relies on derogations for
                specific situations defined in Article 49 of the GDPR. If you
                are a European Union user or a user from another country, the
                company transfers personal information across international
                borders only: with your consent; to perform a contract with you;
                or to establish, exercise, or defend legal claims. Individuals
                in the EU may request a copy of applicable Article 46 agreements
                by emailing privacy@unitedtalent. com
              </p>

              <h2>Your California Privacy Rights </h2>

              <p>
                California Civil Code Section § 1798.83 permits users of our
                Website that are California residents to request certain
                information regarding our disclosure of personal information to
                third parties for their direct marketing purposes. To make such
                a request, please send an email to privacy@unitedtalent.com, or
                write us at the address listed at the top of this notice.
              </p>

              <p>
                The following disclosures are made pursuant to the California
                Consumer Privacy Act of 2018 (“CCPA”) and identify the
                categories of personal information we have collected from
                California consumers on our Website over the past twelve months,
                the categories of sources from which the information was
                collected, the business or commercial purpose for which the
                information was collected, and the categories of third parties
                with whom we shared that information are described below.
              </p>

              <h2>Categories of Personal Information Collected </h2>

              <p>
                The following chart reflects the categories of personal
                information we have collected from California residents during
                the past twelve months, the categories of sources from which the
                information was collected, the business or commercial purpose
                for which the information was collected, the categories of third
                parties to whom we disclosed that information, and our
                anticipated retention period for each category of information.
              </p>

              <img src={termsImageOne} alt="Privacy Policy 1" />
              <img src={termsImageTwo} alt="Privacy Policy 2" />
              <img src={termsImageThree} alt="Privacy Policy 3" />

              <h2>Sources of Collection </h2>

              <ul>
                <li>You.</li>
                <li>
                  Any other individual you have authorized to provide us with
                  your personal information.
                </li>
                <li>Devices you use to access our Website.</li>
              </ul>

              <h2>Business/Commercial Purpose for Collection </h2>

              <ul>
                <li>To communicate with you.</li>
                <li>
                  To provide you with information or services that you request
                  from us.
                </li>
                <li>To improve our Website and present its contents to you.</li>
                <li>
                  To prevent, detect, and investigate fraud or other security
                  incidents.
                </li>
              </ul>

              <h2>
                Categories of Third Parties Receiving Personal Information
              </h2>

              <ul>
                <li>Affiliates</li>
                <li>Technology services</li>
                <li>Website analytics providers </li>
              </ul>

              <p>
                In the past twelve months, we have disclosed personal
                information collected through our Website for business or
                commercial purposes to our affiliate&apos;s technology service
                providers and web analytics providers.
              </p>

              <p>
                The categories of personal information we have disclosed to
                third parties for business or commercial purposes are:
              </p>

              <p>
                Personal identifiers, including name, alias, and email address,
                internet activity information, including online identifiers such
                as Internet Protocol address or device identifier.
              </p>

              <p>
                In the past twelve months, we have not sold personal information
                to third parties. California residents have the right to opt out
                of the sale of their personal information. Because we do not
                sell personal information, we do not offer an opt out.
              </p>

              <h2>Right to Know </h2>

              <p>
                California residents have the right to request that we disclose
                the categories and specific pieces of personal information we
                collected, used, and disclosed during the past twelve months,
                the categories of sources from which their personal information
                was collected, our business or commercial purposes for
                collecting their personal information, and the categories of
                third parties with whom we shared their personal information.
              </p>

              <h2>Right to Delete </h2>

              <p>
                California residents have the right to request deletion of the
                personal information we have collected from them.
              </p>

              <h2>Data Access Request Submission </h2>

              <p>
                You or your authorized agent can submit a request by calling
                1-(877) 678-1770 or by emailing privacy@unitedtalent.com. You
                will need to provide us with personal information for us to
                match with information we have on file to verify your identity
                and residency. The personal information that we use to verify
                your identity and residency will not be used for any other
                purpose.
              </p>

              <h2>Right Against Discrimination </h2>

              <p>
                California residents have the right to not be discriminated
                against for exercising their rights under the CCPA. We do not
                discriminate against California residents who exercise their
                CCPA privacy rights.
              </p>

              <h2>Data Security </h2>

              <p>
                We use commercially reasonable measures to secure information
                collected through the Website. Unfortunately, the transmission
                of information via the internet is not completely secure.
                Although we do our best to protect your personal information, we
                cannot guarantee the security of your personal information
                transmitted to our Website. Any transmission of personal
                information is at your own risk. We are not responsible for
                circumvention of any privacy settings or security measures
                contained on the Website.
              </p>

              <p>
                We retain your information for as long as needed: (i) to conduct
                business with you; (ii) as needed for the purposes outlined in
                this Privacy Policy; (iii) as necessary to comply with our legal
                obligations, resolve disputes, and enforce any agreements.
              </p>

              <h2>Changes to Our Privacy Notice </h2>

              <p>
                We reserve the right to change this privacy notice at any time.
                If we make material changes to how we treat our users&apos;
                personal information, we will notify you in accordance with
                applicable law. The date the privacy policy was last revised is
                identified at the top of this page. You are responsible for
                periodically visiting our Website and this privacy policy to
                check for any changes.
              </p>

              <p>
                Your continued use of our services constitutes your acceptance
                of any amendment of this privacy policy. If following rejection
                of the changes, we will still hold your Personal Information on
                file, to adhere to applicable law.
              </p>

              <h2>How to Complain </h2>

              <p>
                If you have any concerns about our use of your personal
                information, you can make a complaint to us at
              </p>

              <p>United Talent Agency, LLC. </p>

              <p>9336 Civic Center Dr, Beverly Hills, CA 90210 </p>

              <p>1-(877) 678-1770 </p>

              <p>
                <a href="mailto:Privacy@unitedtalent.com">
                  Privacy@unitedtalent.com
                </a>
              </p>

              <h2>UK - GDPR </h2>

              <p>
                You can also complain to the ICO if you are unhappy with how we
                have used your data.
              </p>

              <p>ICO Information Commissioner&apos;s Office </p>

              <p>Wycliffe House </p>

              <p>Water Lane, Wilmslow, Cheshire SK9 5AF </p>

              <p>Helpline: 0303 123 1113 </p>

              <p>
                <a
                  href="https://www.ico.org.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.ico.org.uk
                </a>
              </p>

              <h2>US - CCPA </h2>

              <p>
                If you believe we have violated the CCPA, you may file a
                complaint with the Office of the Attorney General.
              </p>

              <p>Office of the Attorney General </p>

              <p>California Department of Justice </p>

              <p>P.O. Box 944255 Sacramento, CA 94244-2550 </p>

              <p>Helpline: 916.210.6276 or </p>

              <p>(Toll-free in California) 800.952.5225 </p>

              <p>Fax: 916.323.5341 </p>

              <p>
                <a
                  href="https://www.oag.ca.gov"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.oag.ca.gov
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div>
        <FooterContent selected />
      </div>
    </>
  )
}

export default PrivacyPolicy
